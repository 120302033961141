	.participants__container {
		display: flex;
		align-items: center;
		padding: 10px 16px;
		border-bottom: 1px solid var(--palette-live-grey-6);
		cursor: pointer;

		p {
			margin: 0;
		}

		&.single {
			padding: 13px 16px;
		}

		&.disable {
			color: var(--palette-live-grey-6);
		}

		&:last-child {
			border-bottom: none;
		}

		.participants-index {
			font-size: var(--font-size-18);
			margin: 0 10px 0 0;
			font-weight: var(--font-weight-regular);
			color: var(--palette-live-grey-0);
			min-width: 20px;
		}

		.participants-name {
			font-size: var(--font-size-18);
			margin: 0 0 10px 0;
			font-weight: var(--font-weight-regular);

			&:last-child {
				margin: 0 0 0 0;
			}
		}

		.participants-rating {
			font-size: var(--font-size-18);
			margin: 0 0 0 16px;
			font-weight: var(--font-weight-regular);
		}
	}