.stage-group {
	border-radius: 16px;
	border: 1px solid var(--palette-live-grey-7);
	margin: 0 0 15px 0;

	&:last-child {
		margin: 0 0 0 0;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		padding: 14px 20px 14px 20px;
		border-bottom: 1px solid var(--palette-live-grey-7);

		&.no-border {
			border-bottom: none;
		}

		.stage-group__name-wrapper {
			display: flex;

			.stage-group__header-name-group {
				color: var(--palette-live-grey-0);
				font-size: var(--font-size-22);
				margin: 0 16px 0 0;
				font-weight: var(--font-weight-semi-bold);
			}
		}

		.group__header-wrapper {
			display: flex;
			align-items: center;

			.stage-group__header-counter {
				color: var(--palette-live-grey-0);
				font-size: var(--font-size-22);
				margin: 0 0 0 8px;
				font-weight: var(--font-weight-semi-bold);
			}
		}
	}

	&__participants {
		display: flex;

		&-order {
			display: flex;
			flex-direction: column;
			min-height: 100%;
			min-width: 26px;
			border-right: 1px solid var(--palette-live-grey-7);

			div {
				display: flex;
				align-items: center;
				justify-content: center;
				flex: 1;
				width: 26px;
				border-bottom: 1px solid var(--palette-live-grey-7);
				box-sizing: border-box;
				// border-right: 1px solid var(--palette-live-grey-7);

				&:last-child {
					border-bottom: none;
				}
			}
		}

		&-container {
			flex: 1;
		}
	}
}

.stage-group__popup-participants-item {
	border-top: 1px solid var(--palette-live-grey-9);
	display: flex;
	align-items: center;
	padding: 20px 16px;
	box-sizing: border-box;
	cursor: pointer;

	&.empty-node {
		display: flex;
		align-items: center;

		p {
			margin: 0 20px 0 0;
		}

		span {
			width: 55px;
			height: 1px;
			background-color: var(--palette-live-grey-0);
			display: block;
		}
	}

	&.single {
		min-height: 50px;
	}

	&.double {
		min-height: 70px;
		padding: 10px 16px;
	}

	&.disabled {

		.stage-group__popup-participants-name,
		.stage-group__popup-doubles-rating {
			color: var(--palette-live-grey-6);
		}

		.stage-group__popup-participants-doubles {
			span {
				color: var(--palette-live-grey-6);
			}

			.stage-group__popup-participants-doubles-wrapper {
				.stage-group__popup-participants-name--first,
				.stage-group__popup-participants-name--second {
					color: var(--palette-live-grey-6);
				}
			}
		}
	}

	.stage-group__popup-participants-name {
		color: var(--palette-live-grey-0);
		font-size: var(--font-size-18);
		display: flex;
		align-items: center;
		font-weight: var(--font-weight-regular);
	}

	.stage-group__popup-doubles-rating {
		color: var(--palette-live-grey-0);
		font-size: var(--font-size-18);
		margin: 0 16px 0 0;
		font-weight: var(--font-weight-regular);
	}

	.stage-group__popup-participants-doubles {
		display: flex;
		align-items: center;
		min-height: 100%;

		.stage-group__popup-doubles-name {
			color: var(--palette-live-grey-0);
			font-size: var(--font-size-18);
			margin: 0 0 0 0;
			font-weight: var(--font-weight-regular);
		}

		.stage-group__popup-participants-doubles-wrapper {
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin-right: 15px;

			.stage-group__popup-participants-name--first {
				color: var(--palette-live-grey-0);
				font-size: var(--font-size-18);
				margin: 0 0 10px 0;
				font-weight: var(--font-weight-regular);
			}

			.stage-group__popup-participants-name--second {
				color: var(--palette-live-grey-0);
				font-size: var(--font-size-18);
				margin: 0 0 0 0;
				font-weight: var(--font-weight-regular);
			}
		}
	}

	&.notselected {
		cursor: pointer;

		.stage-group__popup-participants-name {
			color: var(--palette-live-blue);

			p {
				color: var(--palette-live-blue);
			}
		}

		.stage-group__popup-participants-doubles {

			.stage-group__popup-doubles-name,
			p {
				color: var(--palette-live-blue);
			}

			.stage-group__popup-participants-doubles-wrapper {

				.stage-group__popup-participants-name--first,
				.stage-group__popup-participants-name--second {
					color: var(--palette-live-blue);
				}
			}
		}
	}
}