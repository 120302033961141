.group-player__single {
	display: flex;
	justify-content: space-between;
	padding: 0 0 0 8px;
	align-items: center;
	cursor: pointer;
	overflow: hidden;

	&-wrapper {
		display: flex;
		flex: 1 1;
		flex-wrap: nowrap;
		align-items: center;
		width: calc(100vw - 200px);

		.group-player__single-index {
			width: 26px;
			margin: 0;
			font-size: var(--font-size-18);
			color: var(--palette-live-grey-0);
			font-weight: var(--font-weight-regular);
		}

		.group-player__single-name {
			margin: 0 4px 0 0;
			font-size: var(--font-size-18);
			color: var(--palette-live-grey-0);
			font-weight: var(--font-weight-regular);
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			box-sizing: border-box;
			flex: 1 1 auto;
			min-width: 0;
			width: calc(100vw - 190px);
			max-width: max-content;
		}

		.group-player__single-rating {
			margin: 0;
			min-width: 40px;
			text-align: center;
			font-size: var(--font-size-18);
			color: var(--palette-live-grey-0);
			font-weight: var(--font-weight-regular);
		}
	}
}

.group-player__double {
	display: flex;
	justify-content: space-between;
	// padding: 0 16px;
	padding: 0px 0px 0px 8px;
	align-items: center;
	cursor: pointer;

	&-wrapper {
		display: flex;
		flex: 1 1;
		flex-wrap: nowrap;
		align-items: center;
		width: calc(100vw - 250px);
		height: 100%;

		.group-player__double-index {
			width: 30px;
			font-size: var(--font-size-18);
			color: var(--palette-live-grey-0);
			font-weight: var(--font-weight-regular);
			flex-shrink: 0;
		}

		.group-player__double-notplaying {
			margin: 0 0 10px 0;
			font-size: var(--font-size-18);
			color: var(--palette-live-grey-0);
			font-weight: var(--font-weight-regular);

			&:last-child {
				margin: 0 0 0 0;
			}
		}

		.group-player__double-name {
			margin: 0 0 2px 0;
			font-size: var(--font-size-18);
			color: var(--palette-live-grey-0);
			font-weight: var(--font-weight-regular);
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			box-sizing: border-box;
			flex: 1 1 auto;
			min-width: 0;
			width: calc(100vw - 176px);
			max-width: max-content;

			&:last-child {
				margin: 0 0 0 0;
			}
		}

		.group-player__double-rating {
			margin: 0;
			font-size: var(--font-size-18);
			color: var(--palette-live-grey-0);
			font-weight: var(--font-weight-regular);
			flex-shrink: 0;
			min-width: 40px;
			text-align: center;
		}
	}
}

.group-player__buttons {
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: grab;
	height: 100%;
	width: 50px;

	.drag-icon {
		width: 24px;
		height: 100%;
		fill: var(--palette-live-grey-6);
	}
}

.name-line {
	width: 55px;
	height: 1px;
	background-color: var(--palette-live-grey-0);
	display: block;
}